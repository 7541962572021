<template>
  <div class="main">
    <Header/>
    <router-view></router-view>
    <Footer/>
  </div>
</template>

<script>
import Footer from "@/components/Footer";
import Header from "@/components/Header";

export default {
  name: "CommonView",
  components: {Header, Footer}
}
</script>

<style scoped>
.main {
  min-width: 1140px;
  margin: 0 auto;
}
</style>
