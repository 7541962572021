<template>
  <div>
    <div class="gray-header">
      <div class="header-box inner">
        <a @click="goToLogin"><p>로그인</p></a>
        <a @click="goToSignUp"><p>회원가입</p></a>
      </div>
    </div>
    <div class="white-header">
      <div class="header-box inner">
        <div class="header-logo">
          <img src="@/assets/images/IA/header_logo_1.png" alt="">
        </div>
        <img src="@/assets/images/IA/header_logo_2.png" alt="">
        <div class="hideBtn">
          <a href="http://www.nrc.go.kr/nrc/main.do" class="left" target="_blank"/>
          <a href="https://www.jj.ac.kr/ot/" class="right" target="_blank"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Header",
  data() {
    return {}
  },
  methods: {
    goToSignUp() {
      this.$router.push({path: '/signUp'}).catch(() => true)
    },
    goToLogin() {
      this.$router.push({path: '/login'}).catch(() => true)
    }
  }
}
</script>

<style scoped>

.gray-header {
  height: 48px;
  width: 100%;
  background: #F0F0F0;
}

.gray-header .header-box {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.gray-header .header-box a {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  margin-left: 40px;
}

.white-header {
  height: 120px;
}

.white-header .header-box {
  height: 100%;
  width: 1140px;
  margin: 0 auto;
  position: relative;
}

.white-header .header-box .header-logo {
  position: absolute;
  top: 41px;
}

.white-header .header-box .header-logo img {
  width: 314px;
  height: 59px;
}

.white-header .header-box > img {
  position: absolute;
  right: 0;
  top: 46px;
  width: 217px;
  height: 54px;
}

.white-header .header-box .hideBtn {
  position: absolute;
  display: flex;
  right: 0; top: 46px;
  width: 217px;
  height: 54px;
}
.white-header .header-box .hideBtn .left {
  width: 130px;
  }
.white-header .header-box .hideBtn .right {
  width: 87px;
}
</style>
